import React from 'react';
import './Banner.css';
import dashboardImage from '../../assets/Banner/DashboardImage.svg';
import bannerLogo from '../../assets/Banner/BannerLogo.svg';
import googleLogo from '../../assets/Banner/google-logo-2.svg';
import intelLogo from '../../assets/Banner/intel-logo-1.svg';
import chubLogo from '../../assets/Banner/chubb-logo-1.svg';
import ibmLogo from '../../assets/Banner/ibm-logo-1.svg';
import skLogo from '../../assets/Banner/SK.svg';
import mellonLogo from '../../assets/Banner/bny-mellon-logo.svg';
import linkedinLogo from '../../assets/Banner/linkedin-logo-1.svg';
import uberLogo from '../../assets/Banner/uber-logo-1.svg';

const Banner = () => {
  // TODO
  // const handleBookDemoClick = () => {
  //   window.open('/book-demo', '_blank');
  // };

  return (
    <section className="banner">
      <div className="banner-content">
        <img src={bannerLogo} alt="Icon" className="banner-icon" />
        <h1 className="banner-heading">Harnessing Gen AI <br/> For Financial Operations</h1>
        <p className="banner-paragraph">
          Leverage generative AI to transform your financial operations. Our advanced solutions streamline processes, improve accuracy, and boost efficiency by automating complex tasks.
        </p>
        <button className="book-demo-button">Book Demo</button>
      </div>
      <div className="dashboard-image-container">
        <img src={dashboardImage} alt="Dashboard" className="dashboard-image" />
      </div>
      <section className="trusted-by">
        <h4 className="trusted-by-heading">Trusted By 25+ Companies</h4>
        <div className="trusted-by-logos">
          <img src={googleLogo} alt="Google" className="logo" />
          <img src={intelLogo} alt="Intel" className="logo" />
          <img src={chubLogo} alt="Chub" className="logo" />
          <img src={ibmLogo} alt="IBM" className="logo" />
          <img src={skLogo} alt="SK" className="logo" />
          <img src={mellonLogo} alt="Mellon" className="logo" />
          <img src={linkedinLogo} alt="LinkedIn" className="logo" />
          <img src={uberLogo} alt="Uber" className="logo" />
        </div>
      </section>
    </section>
  );
};

export default Banner;
