// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Trusted By Section */
.trusted-by {
    text-align: center;
    padding: 60px 20px 20px;
  }
  
  .trusted-by-heading {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .trusted-by-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .trusted-by-logos .logo {
    width: 100px;
    height: auto;
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  
  .trusted-by-logos .logo:hover {
    opacity: 1;
  }
  

  @media (max-width: 768px) {
    .trusted-by-heading {
        font-size: 1rem; 
    }
  
    .trusted-by-logos {
        gap: 10px; 
    }
  
    .trusted-by-logos .logo {
        width: 80px; 
    }
  }

  @media (max-width: 480px) {
    .trusted-by-heading {
        font-size: 0.8rem; 
    }
  
    .trusted-by-logos {
       flex-wrap: wrap; 
        align-items: center;
    }
  
    .trusted-by-logos .logo {
        width: 60px; 
        margin-bottom: 10px; 
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/trustedbysection/trustedby.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,kBAAkB;IAClB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,6BAA6B;EAC/B;;EAEA;IACE,UAAU;EACZ;;;EAGA;IACE;QACI,eAAe;IACnB;;IAEA;QACI,SAAS;IACb;;IAEA;QACI,WAAW;IACf;EACF;;EAEA;IACE;QACI,iBAAiB;IACrB;;IAEA;OACG,eAAe;QACd,mBAAmB;IACvB;;IAEA;QACI,WAAW;QACX,mBAAmB;IACvB;EACF","sourcesContent":["/* Trusted By Section */\n.trusted-by {\n    text-align: center;\n    padding: 60px 20px 20px;\n  }\n  \n  .trusted-by-heading {\n    font-size: 1rem;\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .trusted-by-logos {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 20px;\n  }\n  \n  .trusted-by-logos .logo {\n    width: 100px;\n    height: auto;\n    opacity: 0.7;\n    transition: opacity 0.3s ease;\n  }\n  \n  .trusted-by-logos .logo:hover {\n    opacity: 1;\n  }\n  \n\n  @media (max-width: 768px) {\n    .trusted-by-heading {\n        font-size: 1rem; \n    }\n  \n    .trusted-by-logos {\n        gap: 10px; \n    }\n  \n    .trusted-by-logos .logo {\n        width: 80px; \n    }\n  }\n\n  @media (max-width: 480px) {\n    .trusted-by-heading {\n        font-size: 0.8rem; \n    }\n  \n    .trusted-by-logos {\n       flex-wrap: wrap; \n        align-items: center;\n    }\n  \n    .trusted-by-logos .logo {\n        width: 60px; \n        margin-bottom: 10px; \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
