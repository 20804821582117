import React from 'react';
import './ProblemSection.css';
import CostOverrunIcon from '../../assets/ProblemSection/CostOverrunIcon.svg';
import ScalabilityConcernsIcon from '../../assets/ProblemSection/ScalabilityConcernsIcon.svg';
import ComplianceRisksIcon from '../../assets/ProblemSection/ComplianceRisksIcon.svg';
import VisibilityIcon from '../../assets/ProblemSection/VisibilityIcon.svg';
import PatternsIcon from '../../assets/ProblemSection/PatternsIcon.svg';
import ResourceIcon from '../../assets/ProblemSection/ResourceIcon.svg';
import TeamIcon from '../../assets/ProblemSection/TeamIcon.svg';
import BudgetingIcon from '../../assets/ProblemSection/BudgetingIcon.svg';
import DataIcon from '../../assets/ProblemSection/DataIcon.svg';


const ProblemSection = () => {
  return (
    <section className="problem-section">
      <button className="visibility-small-heading">Problem</button>
      <h2 className="problem-heading">Uncovering Key Issues <br/> Affecting Cloud Optimization</h2>
      <div className="problem-cards">
        <div className="card-row">
          <div className="problem-card half-card left">
            <img src={CostOverrunIcon} alt="Issue 1" className="card-logo" />
            <h3 className="card-heading">Data Transfer Monitoring</h3>
            <p className="card-content">Costs associated with transferring data between different regions or services can be significant enough to impact overall cloud expenditure.</p>
          </div>
          <div className="problem-card full-card">
            <img src={ScalabilityConcernsIcon} alt="Issue 2" className="card-logo" />
            <h3 className="card-heading">Scalability Concerns</h3>
            <p className="card-content">As cloud usage grows, scaling infrastructure efficiently becomes challenging. Inadequate scaling strategies can lead to performance bottlenecks</p>
          </div>
          <div className="problem-card full-card">
            <img src={ComplianceRisksIcon} alt="Issue 3" className="card-logo" />
            <h3 className="card-heading">Compliance Risks</h3>
            <p className="card-content">Adhering to regulatory and compliance requirements in cloud environments can be complex. Non-compliance can result in legal penalties and security breaches. </p>
          </div>
          <div className="problem-card full-card">
            <img src={VisibilityIcon} alt="Issue 4" className="card-logo" />
            <h3 className="card-heading">Visibility & Transparency</h3>
            <p className="card-content">Lack of visibility into cloud spending and resource usage can hinder effective financial management without detailed insights and transparency</p>
          </div>
          <div className="problem-card full-card">
            <img src={PatternsIcon} alt="Issue 5" className="card-logo" />
            <h3 className="card-heading">Unpredictable Patterns</h3>
            <p className="card-content">Fluctuations in cloud usage and costs can be unpredictable, making budgeting and forecasting difficultleading to inconsistent usage patterns.</p>
          </div>
        </div>
        <div className="card-row">
          <div className="problem-card full-card">
            <img src={ResourceIcon} alt="Issue 6" className="card-logo" />
            <h3 className="card-heading">Resource Inefficiency</h3>
            <p className="card-content"> Inefficient use of cloud resources, such as over-provisioning or underutilizing instances, leads to wasted expenditures. </p>
          </div>
          <div className="problem-card full-card">
            <img src={TeamIcon} alt="Issue 7" className="card-logo" />
            <h3 className="card-heading">Cross-Team Coordination</h3>
            <p className="card-content"> Poor coordination between finance, operations, and development teams can lead to misaligned cloud spending strategies.</p>
          </div>
          <div className="problem-card full-card">
            <img src={BudgetingIcon} alt="Issue 8" className="card-logo" />
            <h3 className="card-heading">Budgeting Challenges</h3>
            <p className="card-content">Cloud environments often involve dynamic pricing models and various services, making it challenging to create and stick to accurate budgets. </p>
          </div>
          <div className="problem-card full-card">
            <img src={CostOverrunIcon} alt="Issue 8" className="card-logo" />
            <h3 className="card-heading">Cost Overrun</h3>
            <p className="card-content">Unexpected increases in cloud costs can occur due to unanticipated usage or misconfigured services thereby implementing cost management strategies.</p>
          </div>
          <div className="problem-card half-card right">
            <img src={DataIcon} alt="Issue 9" className="card-logo" />
            <h3 className="card-heading">Data Transfer Monitoring</h3>
            <p className="card-content">Costs associated with transferring data between different regions or services can be significant enough to impact overall cloud expenditure.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProblemSection;
