import React, { useState } from 'react';
import './demoSection.css';
import DemoImage from '../../assets/DemoSection/YTVideoImg.svg';

const DemoSection = () => {
    const [showModal, setShowModal] = useState(false);

    const openVideoPopUp = () => {
        setShowModal(true);
    };

    const closeVideoPopUp = () => {
        setShowModal(false);
    };

    return (
        <section className="demo-section">
            <div className="demo-header">
                <button className="demo-small-heading">See In Action</button>
                <h1 className="demo-main-heading">Watch Our Product Transform <br /> Your Workflow</h1>
            </div>
            <div className="demo-image-container">
                <img
                    src={DemoImage}
                    alt="Product Demo"
                    className="demo-image"
                    onClick={openVideoPopUp}
                />
            </div>

            {showModal && (
                <div className="iframe-overlay show">
                    <div className="iframe-container">
                        <button className="iframe-close-btn" onClick={closeVideoPopUp}>
                            &times;
                        </button>
                        <iframe
                            src="https://www.youtube.com/embed/ANBacXs7WQs?si=rT1OZt3llX7dPTP4"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                        />
                    </div>
                </div>
            )}
        </section>
    );
};

export default DemoSection;
