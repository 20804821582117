import React from 'react';
import './footer.css';
import xenonifyLogo from '../../assets/Navbar/xenonify-logo.svg';
import microsoftLogo from '../../assets/FooterSection/microsoft.svg';
import googleCloudLogo from '../../assets/FooterSection/google-cloud.svg';
import databricksLogo from '../../assets/FooterSection/databrics.svg';
import awsLogo from '../../assets/FooterSection/aws.svg';
import powerBiLogo from '../../assets/FooterSection/powerbi 1.svg';

const Footer = () => {
  return (
    <footer>
      <div className="footer__wrapper">
        <div className="container">
          <div className="footer-inner">
            <div className="footer-items">
              <div className="footer-logo-text">
                <img src={xenonifyLogo} className="footer-logo" alt="xenonify-logo" />
              </div>
              <div className="row">
                <div className="list-name col-lg-6 col-md-6 col-sm-6">
                  <div className="footer-left">
                    <p>We are members of :</p>
                    <ul className="members-ul">
                      <li className="members-li">
                        <a href="https://partner.microsoft.com/en-IN/" target="_blank" rel="noopener noreferrer">
                          <img src={microsoftLogo} alt="Xenonstack Microsoft Member" />
                        </a>
                      </li>
                      <li className="members-li">
                        <a href="https://cloud.withgoogle.com/partners/detail/?id=xenonstack&amp;hl=en-GB" target="_blank" rel="noopener noreferrer">
                          <img src={googleCloudLogo} alt="Google Cloud Member" />
                        </a>
                      </li>
                      <li className="members-li">
                        <a href="https://databricks.com/company/partners" target="_blank" rel="noopener noreferrer">
                          <img src={databricksLogo} alt="Xenonstack Partners Databricks" />
                        </a>
                      </li>
                    </ul>
                    <ul className="members-ul partner-second-row">
                      <li className="members-li">
                        <a href="https://partners.amazonaws.com/partners/001E000001AoR0YIAV/XenonStack" target="_blank" rel="noopener noreferrer">
                          <img src={awsLogo} alt="AWS Partner" />
                        </a>
                      </li>
                      <li className="members-li">
                        <a href="https://powerbi.microsoft.com/en-gb/partners/xenonstack/" target="_blank" rel="noopener noreferrer">
                          <img className="powerBi" src={powerBiLogo} alt="Power BI Partner" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-end">
              <div className="row" style={{ alignItems: 'center' }}>
                <div className="col-sm-9 copyright">
                  <p>
                    Copyright 2024. All rights reserved. | <a href="#" className="text-link">Privacy Policy</a> | <a href="#" className="text-link">Terms of Service</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
