import React from 'react';
import './savings.css';
import SavingAnalysisImg from '../../assets/SavingsSection/SavingAnalysisImg.svg';
import AvgUsageGraph from '../../assets/SavingsSection/AvgUsageImg.svg';
import ResourceSavingsGraph from '../../assets/SavingsSection/ResourceSavingsGraph.svg';

const SavingsSection = () => {
  return (
    <div className="savings-container">
      <div className="savings-background">
        <button className="heading-small">Savings</button>
        <h1 className="heading-large">Maximize Cloud Savings <br /> Effortlessly Here</h1>
        <div className="card-wrapper">
          <div className="card-item card-item-large">
            <h3 className="card-title">Savings Analysis for Resources</h3>
            <img src={SavingAnalysisImg} alt="Savings Analysis for Resources" className="card-image" />
          </div>
          <div className="card-item">
            <h3 className="card-title">Average Usage</h3>
            <img src={AvgUsageGraph} alt="Average Usage" className="card-image" />
          </div>
          <div className="card-item-monthly-spending">
            <h3 className="card-title">Average Monthly Spending</h3>
            <h1 className='savings-card-value'>$48.4K</h1>
          </div>
          <div className="card-item">
            <h3 className="card-title">Resource-wise Savings</h3>
            <img src={ResourceSavingsGraph} alt="Resource-wise Savings" className="resource-card-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavingsSection;
