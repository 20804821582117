import React from 'react';
import './recommendation.css';
import RightsizingImg from '../../assets/RecommendationSection/RightsizingImg.svg';
import AnomalyDetectionImg from '../../assets/RecommendationSection/AnomalyDetectionImg.svg';
import AllocationImg from '../../assets/RecommendationSection/AllocationImg.svg';
import NetworkImg from '../../assets/RecommendationSection/NetworkImg.svg';
import PerformanceImg from '../../assets/RecommendationSection/PerformanceImg.svg';
import RecommendationIcon from '../../assets/RecommendationSection/recommendationIcon.svg';

const RecommendationsSection = () => {
  return (
    <section className="recommendations-container">
      <div className="recommendations-section">
        <div className="recommendations-header">
          <button className="recommendations-small-heading">Recommendations</button>
          <h1 className="recommendations-large-heading">
            Unlock Cloud Potential For Cost And <br/> Performance Optimization
          </h1>
          <p className="recommendations-description">
            Discover how our FinOps expertise can streamline your financial operations, optimize costs, and drive efficiency—let’s transform your financial strategy together.
          </p>
        </div>

        <div className="recommendations-grid">
          <div className="recommendation-card large-card">
            <div className="recommendation-card-content">
              <img src={RecommendationIcon} alt="Icon 1" className="recommendation-icon" />
              <h3 className="recommendation-heading">Rightsizing Recommendations</h3>
            </div>
            
            <p className="recommendation-text">
            Match instance sizes to workload needs to balance cost and performance, avoiding over-provisioning.
            </p>
            <img src={RightsizingImg} alt="Optimization" className="recommendation-image-row1" />
          </div>
          <div className="recommendation-card large-card">
            <div className="recommendation-card-content">
              <img src={RecommendationIcon} alt="Icon 2" className="recommendation-icon" />
              <h3 className="recommendation-heading">Anomaly Detection</h3>
            </div>
            
            <p className="recommendation-text">
            Spot unexpected changes in usage or costs with automated alerts to prevent overspending.
            </p>
            <img src={AnomalyDetectionImg} alt="Cost Management" className="recommendation-image-row1" />
          </div>
        </div>

        <div className="recommendations-grid-2">
          <div className="recommendation-card small-card">
            <div className="recommendation-card-content">
              <img src={RecommendationIcon} alt="Icon 3" className="recommendation-icon" />
              <h3 className="recommendation-heading">Cost Allocation and Tagging</h3>
            </div>
            
            <p className="recommendation-text">
            Use tagging to track and manage costs by department or project effectively.
            </p>
            <img src={AllocationImg} alt="Performance Tuning" className="recommendation-image" />
          </div>
          <div className="recommendation-card small-card">
            <div className="recommendation-card-content">
              <img src={RecommendationIcon} alt="Icon 4" className="recommendation-icon" />
              <h3 className="recommendation-heading">Network Optimization</h3>
            </div>
            
            <p className="recommendation-text">
            Improve network performance with optimized VPC design & content delivery.
            </p>
            <img src={NetworkImg} alt="Resource Allocation" className="recommendation-image" />
          </div>
          <div className="recommendation-card small-card">
            <div className="recommendation-card-content">
              <img src={RecommendationIcon} alt="Icon 5" className="recommendation-icon" />
              <h3 className="recommendation-heading">Performance Monitoring</h3>
            </div>
            
            <p className="recommendation-text">
            Monitor metrics and optimize resources to ensure top performance.
            </p>
            <img src={PerformanceImg} alt="Cost Efficiency" className="recommendation-image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecommendationsSection;
