// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.explore-content {
    width: 75%;
    margin: auto;
    text-align: center;
}
.explore-content h2 {
    font-size: 2.5rem;
    margin-bottom: 15px;
}
.explore-content p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
}
.explore-now-btn{
    font-size: 1.2rem;
    padding: 15px 30px;
    border-radius: 12px;
    color: #2D28BC;
    background-color: #ffffff;
    border-color: #2D28BC;
}
section.explore-section {
    margin-bottom: 120px;
}

@media (max-width:768px){
    .explore-content h2{
        font-size: 1.8rem;
    }
    section.explore-section {
        margin-bottom: 70px;
    }
}

@media (max-width:480px){
    .explore-content h2{
        font-size: 1.4rem;
    }
    .explore-content p{
        font-size: 0.8rem;
        margin-bottom: 28px;
    }
    .explore-now-btn {
        font-size: 0.9rem;
        padding: 10px 22px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/EnterpriseExploreSection/Explore.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB;AACA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,iBAAiB;QACjB,mBAAmB;IACvB;IACA;QACI,iBAAiB;QACjB,kBAAkB;IACtB;AACJ","sourcesContent":[".explore-content {\n    width: 75%;\n    margin: auto;\n    text-align: center;\n}\n.explore-content h2 {\n    font-size: 2.5rem;\n    margin-bottom: 15px;\n}\n.explore-content p {\n    font-size: 1.2rem;\n    color: #555;\n    margin-bottom: 40px;\n}\n.explore-now-btn{\n    font-size: 1.2rem;\n    padding: 15px 30px;\n    border-radius: 12px;\n    color: #2D28BC;\n    background-color: #ffffff;\n    border-color: #2D28BC;\n}\nsection.explore-section {\n    margin-bottom: 120px;\n}\n\n@media (max-width:768px){\n    .explore-content h2{\n        font-size: 1.8rem;\n    }\n    section.explore-section {\n        margin-bottom: 70px;\n    }\n}\n\n@media (max-width:480px){\n    .explore-content h2{\n        font-size: 1.4rem;\n    }\n    .explore-content p{\n        font-size: 0.8rem;\n        margin-bottom: 28px;\n    }\n    .explore-now-btn {\n        font-size: 0.9rem;\n        padding: 10px 22px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
