import { useState } from 'react';
import Calendar from 'react-calendar';
import book from '../../assets/BookDemoSection/book-demo.svg';
import arrow from '../../assets/BookDemoSection/left-arrow.svg';
import timer from '../../assets/BookDemoSection/timer.svg';
import right from '../../assets/BookDemoSection/right-arrow.svg';
import confirmation from '../../assets/BookDemoSection/confirmation.svg';
import cross from '../../assets/BookDemoSection/cross.png';
import 'react-calendar/dist/Calendar.css';
import './bookDemo.css';
import axios from 'axios';
import TextField from '@mui/material/TextField';

const BookDemo = () => {
        const [email, setEmail] = useState('');
        const [name, setName] = useState('');
        const [contact, setContact] = useState('');
        const [emailError, setEmailError] = useState(false);
        const [nameError, setNameError] = useState(false);
        const [contactError, setContactError] = useState(false);
        const [emailHelperText, setEmailHelperText] = useState('');
        const [nameHelperText, setNameHelperText] = useState('');
        const [contactHelperText, setContactHelperText] = useState('');
        const [loading, setLoading] = useState(false);
        const [success, setSuccess] = useState(false);
        const [error, setError] = useState('');
      
        // Email validation function
        const validateEmail = (value) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value);
        };
      
        // Name validation function
        const validateName = (value) => {
          const nameRegex = /^[A-Za-z\s]+$/; // Only letters and spaces allowed
          return value.trim() !== '' && nameRegex.test(value);
        };
      
        // Contact number validation function
        const validateContact = (value) => {
          const contactRegex = /^[0-9]{10}$/; // Exactly 10 digits
          return contactRegex.test(value);
        };
      
        const handleEmailChange = (event) => {
          const value = event.target.value;
          setEmail(value);
      
          if (validateEmail(value)) {
            setEmailError(false);
            setEmailHelperText('');
          } else {
            setEmailError(true);
            setEmailHelperText('Please enter a valid email.');
          }
        };
      
        const handleNameChange = (event) => {
          const value = event.target.value;
          setName(value);
      
          if (validateName(value)) {
            setNameError(false);
            setNameHelperText('');
          } else {
            setNameError(true);
            setNameHelperText('Please enter a valid name.');
          }
        };
      
        const handleContactChange = (event) => {
          const value = event.target.value;
          setContact(value);
      
          if (validateContact(value)) {
            setContactError(false);
            setContactHelperText('');
          } else {
            setContactError(true);
            setContactHelperText('Please enter a valid 10-digit contact number.');
          }
        };
        const [selectedOption, setSelectedOption] = useState(null);
      
        const handleSelect = (option) => {
          setSelectedOption(option);
        };
        const [currentStep, setCurrentStep] = useState(1);
        const [date, setDate] = useState(new Date());
        const [selectedSlot, setSelectedSlot] = useState(null);
      
        const timeSlots = [
          "9:00 AM",
          "9:30 AM",
          "10:00 AM",
          "10:30 AM",
          "11:00 AM",
          "11:30 AM"
        ];
      
        const handleBackClick = () => {
          setCurrentStep((prevStep) => prevStep - 1);
        };
      
        const handleSlotClick = (slot) => {
          setSelectedSlot(slot);
        };
      
        const today = new Date();
      
        const handleNextClick = () => {
          if (currentStep === 1) {
            if (!date) {
              setErrors((prevErrors) => ({ ...prevErrors, dateError: 'Please select a date.' }));
            } else if (!selectedSlot) {
              setErrors((prevErrors) => ({ ...prevErrors, slotError: 'Please select a time slot.' }));
            } else if (date < new Date()) {
              setErrors((prevErrors) => ({ ...prevErrors, futureDateError: 'Please select a date and time in the future.' }));
            } else {
              setCurrentStep((prevStep) => prevStep + 1);
              setErrors({
                dateError: '',
                slotError: '',
                futureDateError: '',
              });
            }
          } else if (currentStep === 2) {
      
            if (validateEmail(email) && validateName(name) && validateContact(contact) && selectedSlot) {
              scheduleMeeting();
            } else {
              // Handle validation errors
              setEmailError(!validateEmail(email));
              setNameError(!validateName(name));
              setContactError(!validateContact(contact));
            }
      
          } else {
      
            setCurrentStep((prevStep) => prevStep + 1);
      
          }
      
        };
      
        const scheduleMeeting = async () => {
          setLoading(true);
          setError('');
          setSuccess(false);
      
          // Format the date to 'YYYY-MM-DDTHH:mm:ssZ'
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
      
          // Convert selectedSlot (e.g., "10:00 AM") to 24-hour format
          const timeParts = selectedSlot.split(' ');
          const timeComponents = timeParts[0].split(':');
          let hours = parseInt(timeComponents[0], 10);
          const minutes = timeComponents[1];
          const period = timeParts[1]; // AM or PM
      
          if (period === 'PM' && hours < 12) hours += 12;
          if (period === 'AM' && hours === 12) hours = 0;
          if (period === 'AM' && hours === 12) hours = 0;
      
          const formattedHours = String(hours).padStart(2, '0');
          const formattedTime = `${formattedHours}:${minutes}:00`;
      
          // Combine date and time into the required format
          const formattedDateTime = `${year}-${month}-${day}T${formattedTime}Z`;
      
          const payload = {
            user_email: email,
            date_time: formattedDateTime,
            duration: "30m"
          };
      
          try {
            const response = await axios.post('https://akira-ai-auth.lab.neuralcompany.team/v1/open/meeting', payload);
            setSuccess(true);
            setCurrentStep(3);
          } catch (err) {
            setError('Failed to schedule the meeting. Please try again.');
          } finally {
            setLoading(false);
          }
        };
        const [errors, setErrors] = useState({
          dateError: '',
          slotError: '',
          futureDateError: '',
        });
        const formattedSlot = selectedSlot ? selectedSlot : '';
      
        const formattedDate = `${date.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' })}`;
        const formattedSlotRange = selectedSlot ? formattedSlot : '';
        const [errorActive, setErrorActive] = useState(false);
      
        return (
          <div className='callender-page'>
            <style>
              {`
                * {
                  margin: 0;
                  padding: 0;
                }
                .react-calendar { 
                  width: 400px;
                  max-width: 100%;
                  background-color: #fff;
                  color: #222;
                  border-radius: 8px;
                  font-family: Arial, Helvetica, sans-serif;
                  line-height: 1.125em;
                  border: none;
                }
                .react-calendar__navigation button {
                  color: #0D5C9C;
                  min-width: 44px;
                  background: none;
                  font-size: 20px;
                  font-weight: 800;
                  margin-top: 8px;
                }
                .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
                background: #97D0FF66;
                border: 1px solid #4399DF
                color: #0D5C9C;
                }
                .react-calendar__tile {
                padding: 15px 6.6667px;
                }
                .react-calendar__navigation button:enabled:hover,
                .react-calendar__navigation button:enabled:focus {
                  background-color: #f8f8fa;
                }
                .react-calendar__navigation button[disabled] {
                  background-color: #f0f0f0;
                }
                abbr[title] {
                  text-decoration: none;
                }
                .react-calendar__tile:enabled:hover,
                .react-calendar__tile:enabled:focus {
                  border-radius: 6px;
                }
                  .react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, .react-calendar__century-view__decades__decade--neighboringCentury {
          color: #707070;
      }
          .react-calendar__month-view__days__day--weekend {
          color: #d10000;
      }
                .react-calendar__tile--now {
                  background: #6f48eb33;
                  border-radius: 6px;
                  font-weight: bold;
                  color: #6f48eb;
                }
                .react-calendar__tile--now:enabled:hover,
                .react-calendar__tile--now:enabled:focus {
                  border-radius: 6px;
                  font-weight: bold;
                  color: #6f48eb;
                }
                .react-calendar__tile--hasActive:enabled:hover,
                .react-calendar__tile--hasActive:enabled:focus {
                  // background: #f8f8fa;
                }
                .react-calendar__tile--active {
                  border-radius: 6px;
                  font-weight: bold;
                  color: white;
                }
                .react-calendar__tile--active:enabled:hover,
                .react-calendar__tile--active:enabled:focus {
                }
                .react-calendar--selectRange .react-calendar__tile--hover {
                  background-color: #f8f8fa;
                }
                .react-calendar__tile--range {
                  background: #f8f8fa;
                  color: #6f48eb;
                  border-radius: 0;
                }
                .react-calendar__tile--rangeStart {
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  border-top-left-radius: 6px;
                  border-bottom-left-radius: 6px;
                  background: #6f48eb;
                  color: white;
                }
                .react-calendar__tile--rangeEnd {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                  border-top-right-radius: 6px;
                  border-bottom-right-radius: 6px;
                  background: #97D0FF66;
                  color:#0D5C9C;
                }
              `}
            </style>
            <div className={`callender-wrapper ${errors.dateError || errors.slotError || errors.futureDateError ? '' : ''}`}>
              <div className={`callernder-top-wrapper ${errors.dateError || errors.slotError || errors.futureDateError ? 'blur-elements' : ''}`}>
                <div className={`callender-left-wrapper ${currentStep === 3 ? 'confirmation-step' : ''}`}>
                  {currentStep === 1 && (
                    <>
                      <img src={book} alt="Book Demo" />
                      <div className='scheduled-demo'>
                        <p>Get a walkthrough</p>
                        <h3>Schedule a demo</h3>
                      </div>
                      <div className='duration'>
                        <img src={timer} alt="Timer" />
                        <h4>30 min</h4>
                      </div>
                    </>
                  )}
                  {currentStep === 2 && (
                    <>
                      <div className='slot-wrapper-container'>
                        <div className='slot-top-wrapper'>
                          <div className='scheduled-demo'>
                            <p>Get a walkthrough</p>
                            <h3>Schedule a demo</h3>
                          </div>
                          <div className='duration'>
                            <img src={timer} alt="Timer" />
                            <h4>30 min</h4>
                          </div>
                        </div>
                        <div className='selected-slot'>
                          <div className='selected-date'>
                            <p>Date</p>
                            <h3>{formattedDate}</h3>
                          </div>
                          <div className='selected-date'>
                            <p>Time</p>
                            <h3>{formattedSlot}</h3>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {currentStep === 3 && (
                    <>
                      <div className='confirmation-container'>
                        <img src={confirmation} alt="Confirmation" />
                        <div className='confirmation-header'>
                          <h1>We just scheduled a demo with you!</h1>
                          <p>A calendar invitation for your upcoming session has
                            been sent to your email.</p>
                        </div>
                        <div className='slot-confirmation'>
                          <div className='selected-date'>
                            <p>Date</p>
                            <h3>{formattedDate}</h3>
                          </div>
                          <div className='selected-date'>
                            <p>Time</p>
                            <h3>{formattedSlot}</h3>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {currentStep === 1 && (
                  <div className={`calender-container-wrapper ${errors.dateError || errors.slotError || errors.futureDateError ? 'blur-elements' : ''}`}>
                    <div className='calendar-container'>
                      <p>Select a Date and Time</p>
                      <Calendar onChange={setDate} value={date} minDate={today} />
                    </div>
                    <div className='calender-right-wrapper'>
                      {timeSlots.map((slot) => (
                        <div
                          key={slot}
                          className={`choose-slot ${selectedSlot === slot ? 'selected' : ''}`}
                          onClick={() => handleSlotClick(slot)}
                        >
                          <h4>{slot}</h4>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {currentStep === 2 && (
                  <div className='book-details'>
                    <div className='top-book-details'>
                      <p>Enter your details</p>
                      <h3>Personal Information</h3>
                    </div>
                    <div className='middle-book-details'>
                      <TextField
                        error={emailError}
                        id="outlined-email"
                        label="Email"
                        value={email}
                        onChange={handleEmailChange}
                        helperText={emailHelperText}
                        variant="outlined"
                        margin="normal"
                        className="custom-width"
                      />
                      <TextField
                        error={nameError}
                        id="outlined-name"
                        label="Name"
                        value={name}
                        onChange={handleNameChange}
                        helperText={nameHelperText}
                        variant="outlined"
                        margin="normal"
                        className="custom-width"
                      />
                      <TextField
                        error={contactError}
                        id="outlined-contact"
                        label="Contact Number"
                        value={contact}
                        onChange={handleContactChange}
                        helperText={contactHelperText}
                        variant="outlined"
                        margin="normal"
                        className="custom-width"
                      />
                    </div>
                    <div className='bottom-book-details'>
                      <p>Any experience with handling Financial Operations on Cloud</p>
                      <div className='answer-wrapper'>
                        <div
                          className={`yes-wrapper ${selectedOption === 'yes' ? 'selected' : ''}`}
                          onClick={() => handleSelect('yes')}
                        >
                          <div className={`check-box ${selectedOption === 'yes' ? 'checked' : ''}`}></div>
                          <p>Yes</p>
                        </div>
                        <div
                          className={`no-wrapper ${selectedOption === 'no' ? 'selected' : ''}`}
                          onClick={() => handleSelect('no')}
                        >
                          <div className={`check-box ${selectedOption === 'no' ? 'checked' : ''}`}></div>
                          <p>No</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {currentStep !== 3 ? (
                <div className={`calender-bottom-wrapper ${errors.dateError || errors.slotError || errors.futureDateError ? 'blur-elements' : ''}`}>
                  <div
                    className={`back-wrapper ${currentStep === 1 ? 'disabled' : ''}`}
                    onClick={currentStep === 1 ? null : handleBackClick}
                  >
                    <img src={arrow} alt="Arrow" />
                    <h4>Back</h4>
                  </div>
                  <div
                    className={`next-wrapper ${currentStep === 2 ? 'scheduled' : ''}`}
                    onClick={currentStep === 1 || currentStep === 2 ? handleNextClick : null}
                  >
                    <h4>{currentStep === 2 ? 'Scheduled' : 'Next'}</h4>
                    <img src={right} alt="Right Arrow" />
                  </div>
                </div>
              ) : (
                <div className='confirmation-bottom'>
                  <div className='get-back-home'>
                    <p>Get Back Home</p>
                  </div>
                  <div className='resend-email'>
                    <p>Resend E-mail</p>
                  </div>
                </div>
              )}
              {errors.dateError || errors.slotError || errors.futureDateError ? (
                <div className="error">
                  <div className='cross-wrapper'>
                    <img src={cross}></img>
                  </div>
                  <h3>Error</h3>
                  <p>Please select time and slot to continue</p>
                  <div className='okay-wrapper'>
                    <h4 onClick={() => {
                      setErrorActive(false);
                      window.location.reload();
                    }}>OK</h4>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        );
      }

export default BookDemo;
