import React from 'react';
import './ctasection.css'; 

const CTASection = () => {
  // TODO
  // const handleBookDemoClick = () => {
  //   window.open('/book-demo', '_blank');
  // };
  return (
    <section className="cta-section cta-container">
     
        <div className="cta-content">
          <h1 className="cta-heading">Your Next Leap In Cloud Innovation Starts Here</h1>
          <p className="cta-paragraph">
            Contact our team to explore how our solutions can drive your success.
          </p>
          <button className="book-demo-button">Book Demo</button>
        </div>
    
    </section>
  );
};

export default CTASection;
