import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Banner from './components/Banner/Banner';
import ProblemSection from './components/problemsection/ProblemSection';
import AutomationSection from './components/automation/automation';
import SolutionSection from './components/SolutionSection/solution';
import DemoSection from './components/demovideo/demoSection';
import SavingsSection from './components/savings/savings';
import FeaturesSection from './components/features/features';
import VisibilitySection from './components/visibility/visibility';
import RecommendationsSection from './components/recommendations/recommendation';
import CTASection from './components/ctasection/ctasection';
import Footer from './components/footer/footer';
import BookDemo from './components/bookdemo/bookDemo';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Banner />
              <ProblemSection />
              <AutomationSection />
              <SolutionSection />
              <DemoSection />
              <SavingsSection />
              <FeaturesSection />
              <VisibilitySection />
              <RecommendationsSection />
              <CTASection />
              <Footer />
            </>
          }
        />
        <Route path="/book-demo" element={<BookDemo />} />
      </Routes>
    </Router>
  );
}

export default App;
