import React from 'react';
import './automation.css'; 
import AutomationActive from '../../assets/AutomationSection/automationActive.svg';
import AutomationInactive from '../../assets/AutomationSection/automationFinops.svg';

const AutomationSection = () => {
    return (
        <section className="automation-section">
            <div className="automation-header">
                <button className="small-heading">Automation</button>
                <h1 className="large-heading">Bridging Your Tools For Optimal <br/>Performance</h1>
            </div>
            <div className="automation-cards">
                <div className="automation-card-row top-row">
                    <div className="automation-card">
                        <div className="card-header">
                            <img src={AutomationInactive} alt="Tool 1" className="card-icon" />
                            <h3 className="automation-card-heading">FinOps for Azure</h3>
                        </div>
                        <p className="card-description">Controls and reduces spending in Azure through tailored cost management and optimization strategies.</p>
                    </div>
                    <div className="automation-card">
                        <div className="card-header">
                            <img src={AutomationInactive} alt="Tool 2" className="card-icon" />
                            <h3 className="automation-card-heading">FinOps for AWS</h3>
                        </div>
                        <p className="card-description">Manages and optimizes costs for Amazon Web Services, focusing on efficient resource use and budget control.</p>
                    </div>
                    <div className="automation-card">
                        <div className="card-header">
                            <img src={AutomationInactive} alt="Tool 3" className="card-icon" />
                            <h3 className="automation-card-heading">FinOps for Databricks</h3>
                        </div>
                        <p className="card-description">Optimizes costs and resource usage for Databricks, enhancing efficiency in data processing.</p>
                    </div>
                </div>
                <div className="automation-card-row bottom-row">
                    <div className="automation-card">
                        <div className="card-header">
                            <img src={AutomationInactive} alt="Tool 4" className="card-icon" />
                            <h3 className="automation-card-heading">FinOps for Snowflake</h3>
                        </div>
                        <p className="card-description">Manages and optimizes expenses in Snowflake’s data platform for better cost efficiency.</p>
                    </div>
                    <div className="automation-card">
                        <div className="card-header">
                            <img src={AutomationInactive} alt="Tool 5" className="card-icon" />
                            <h3 className="automation-card-heading">FinOps for Hybrid Cloud</h3>
                        </div>
                        <p className="card-description">Balances and optimizes costs across both on-premises and cloud environments in a hybrid setup.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};
  
export default AutomationSection;
