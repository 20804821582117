import React from 'react';
import './features.css';
import CostreportIcon from '../../assets/FeaturesSection/UtilizationIcon.svg';
import DataDrivenIcon from '../../assets/FeaturesSection/DataDrivenIcon.svg';
import ServiceInvestigationIcon from '../../assets/FeaturesSection/ServiceInvestigationIcon.svg';
import DataQualityIcon from '../../assets/FeaturesSection/DataQualityIcon.svg';
import BudgetiForecastingIcon from '../../assets/FeaturesSection/BudgetiForecastingIcon.svg';
import AutoRemediationIcon from '../../assets/FeaturesSection/AutoRemediationIcon.svg';
import AutoTaggingIcon from '../../assets/FeaturesSection/AutoTaggingIcon.svg';

const FeaturesSection = () => {
  return (
    <div className="features-container">
      <button className="small-heading">Features</button>
      <h1 className="features-heading">Unleashing The Key Features<br />Revolutionize FinOps</h1>
      <p className="features-description">
        Discover how our advanced features can transform your financial operations and drive unparalleled efficiency. Discover how our advanced features can transform your financial operations.
      </p>
      <div className="features-grid">
        <div className="features-column">
          <div className="feature-card">
            <img src={CostreportIcon} alt="Feature 1 Icon" className="feature-icon" />
            <h3 className="feature-heading">Cost & Utilization Reporting</h3>
            <p className="feature-description">Track and report modifications in cost and resource utilization in real-time, providing clear visibility and control over your financial and operational metrics</p>
          </div>
          <div className="feature-card">
            <img src={DataDrivenIcon} alt="Feature 2 Icon" className="feature-icon" />
            <h3 className="feature-heading">Data-Driven Recommendations</h3>
            <p className="feature-description">Receive actionable recommendations based on comprehensive data analysis of your AWS environment, optimizing resource allocation and cost efficiency</p>
          </div>
        </div>
        <div className="features-column">
          <div className="feature-card">
            <img src={ServiceInvestigationIcon} alt="Feature 3 Icon" className="feature-icon" />
            <h3 className="feature-heading">Service Investigation</h3>
            <p className="feature-description">Gain deep insights into your services with advanced investigation tools that analyze</p>
          </div>
          <div className="feature-card">
            <img src={DataQualityIcon} alt="Feature 4 Icon" className="feature-icon" />
            <h3 className="feature-heading">Data Quality Management</h3>
            <p className="feature-description">Enhance data accuracy with robust quality checks, ensuring reliable and actionable insights</p>
          </div>
          <div className="feature-card">
            <img src={BudgetiForecastingIcon} alt="Feature 5 Icon" className="feature-icon" />
            <h3 className="feature-heading">Budgeting and Forecasting</h3>
            <p className="feature-description">Dynamic budgeting adjusts to cloud changes, while predictive analytics costs from historical data.</p>
          </div>
        </div>
        <div className="features-column">
          <div className="feature-card">
            <img src={AutoRemediationIcon} alt="Feature 6 Icon" className="feature-icon" />
            <h3 className="feature-heading">Auto Remediation</h3>
            <p className="feature-description">Quickly resolve issues with automatic remediation of assets, minimizing downtime & maintaining optimal performance with minimal intervention.</p>
          </div>
          <div className="feature-card">
            <img src={AutoTaggingIcon} alt="Feature 7 Icon" className="feature-icon" />
            <h3 className="feature-heading">Auto Tagging</h3>
            <p className="feature-description">Effortlessly categorize assets with automated tagging, ensuring consistent & accurate resource management without manual input</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
