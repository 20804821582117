import React from 'react';
import './visibility.css';
import CloudEnablementImg from '../../assets/VisibilitySection/CloudEnablementImg.svg';
import AnnualForecastImg from '../../assets/VisibilitySection/AnnualForecastImg.svg';

const VisibilitySection = () => {
  return (
    <div className='visibility-wrapper'>
    <section className="visibility-section">
      <div className="visibility-header">
        <button className="visibility-small-heading">Visibility</button>
        <h1 className="visibility-big-heading">Complete 360° View <br/>  To Your Spendings s Here!</h1>
      </div>

      <div className="visibility-content">
        <div className="content-row">
          <div className="content-image">
            <img src={CloudEnablementImg} alt="Visibility Insights" />
          </div>
          <div className="content-text">
            <button className="visibility-button sign-up-button">Sign Up</button>
            <h3 className="content-heading">Cloud Enablement with FinOpsGA</h3>
            <p className="content-paragraph">In the initial phase of cloud adoption, organizations focus on basic setups and migrations. As they progress, AI enhances this by automating and optimizing cloud processes for better integration and efficiency. <br/> At a mature stage, AI ensures complete cloud efficiency by fine-tuning performance, controlling costs, and supporting strategic decision-making.</p>
            {/* <button className="discover-button">Explore Now</button> */}
          </div>
        </div>
        
        <div className="content-row reverse">
        <div className="content-image">
            <img src={AnnualForecastImg} alt="Budget Tracking" />
          </div>
          <div className="content-text">
            <button className="visibility-button sign-up-button">Discover</button>
            <h3 className="content-heading">Annual Forecasting with FinOpsGA</h3>
            <p className="content-paragraph">Basic forecasting depends on historical data to estimate future trends. AI improves this process by analysing trends and patterns to refine predictions. <br/> Advanced forecasting leverages AI for precise, strategic projections that align closely with business goals, enhancing overall forecasting accuracy and relevance.</p>
            {/* <button className="discover-button">Get Started</button> */}
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default VisibilitySection;
